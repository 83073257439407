.img-preview{
    max-height: 100px;
    width: auto;
}

input[type=number], input[type=text]{
    min-width: 100px;
}

.table-responsive .ddl__menu{
	position: relative !important;
}
