body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*font-size: 15px;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.col-form-label{
  font-weight: 600;
}


.center-login{
  height: 100vh;
  width: 100vw;
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.login{
  height: 85vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.field-required::after{
  content: '*';
  color: red;
}

.table thead th {
  text-align: center;
}

.job-status{
  font-size: 1.5em;
  font-weight: bold;
}

.select2-dropdown{
  z-index: 2051;
}

.ui-menu.ui-widget.ui-widget-content.ui-autocomplete{
  z-index: 2051;
}

.nav-link.disabled{
  opacity: 0.5;
}


.table-borderless > thead > tr > th, .table-borderless > tbody > tr > td{
  border: none;
}

.table th{
  text-align: center;
}

.main-table tr{
  
}

.btn-command{
  width: 75px;
}

.table td{
  padding-bottom: 0;
  padding-top: 10px;
}

.img-bank.BAY{
  background-color:#fec43b
}

.img-bank.TMB{
  background-color:#1279be
}

.img-bank.KBANK{
  background-color:#138f2d
}

.img-bank.KTB{
  background-color:#1ba5e1
}

.img-bank.BBL{
  background-color:#1e4598
}

.img-bank.SCB{
  background-color:#4e2e7f
}

.img-bank.UOB{
  background-color:#00256b
}

.img-bank {
    width: 25px;
    height: 25px;
}

.nav .label, .ibox .label{
  font-size: 1em;
}